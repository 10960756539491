import styled from 'styled-components';

export const ScannerContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['width', 'height'].includes(prop)
})<{ width: number; height: number }>`
  background-color: #000000;
  height: var(--app-height);

  display: flex;
  align-items: center;
  position: relative;

  & div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & svg {
    position: absolute;
    width: ${({width}) => width}px;
    height: ${({height}) => height}px;
    top: auto !important;
    left: auto !important;
  }
`;

export const BackButton = styled.button`
  background-color: #F0F2F8;
  outline: 3px solid #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  position: fixed;
  right: 20px;
  bottom: 50px;

  & svg {
    z-index: 1;
  }
`;
