import styled from 'styled-components';

export const BalloonWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;


  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BalloonOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.33);
  z-index: 3;
`;

export const Balloon = styled.div`
  width: 300px;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 4px;
  position: relative;
  z-index: 10;
  border: 1px solid var(--main-color);
`;

export const CloseBtn = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  //display: flex;
  //align-items: flex-start;
  //justify-content: flex-end;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;


export const Footer = styled.div`
  background-color: #f3f3f3;
  padding: 5px;
  border-radius: 0 0 10px 10px;
`;

export const Link = styled.a`
  color: blue;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
`;
