import React, {useState} from 'react';
import {Box, Col, Container, Row, Separator} from '../../utils';
import {Paragraph, Switcher} from '../../fields';
import {Card} from './components';
import {Location, useLocation, useParams} from 'react-router-dom';
import {Loader} from '../../components/loader';
import {Option} from '../../store/types';
import {useGetShiftOrdersQuery} from './store/shift.orders.api';
import dayjs from 'dayjs';

// Жду
// CookingStarted
// WaitCooking
// Waiting
// ReadyForCooking


// Везу
// CookingCompleted
// OnWay


// Отдал
// Delivered
// Closed

// Отмена
// Cancelled

export const statusProductOptions: Option[] = [
    {label: 'Жду', value: 'Waiting'},
    {label: 'Везу', value: 'OnWay'},
    {label: 'Отдал', value: 'Delivered'},
    {label: 'Отмена', value: 'Cancelled'}
];

const cases: { [key: string]: string } = {
    Waiting: 'CookingStarted,WaitCooking,Waiting,ReadyForCooking',
    OnWay: 'CookingCompleted,OnWay',
    Delivered: 'Delivered,Closed',
    Cancelled: 'Cancelled',
};

const getPeriod = (data: string): string => {
    return `${dayjs(data, 'YYYY-MM-DD').format('DD MMM YYYY')}`;
};


export const ShiftOrders = () => {
    const {id} = useParams<{ id: string }>();
    const location = useLocation();
    const {state} = location as Location & { state: { ObjName: string, data: string } };

    const [selected, setSelected] = useState<Option>(statusProductOptions[0]);

    const {data, isLoading} = useGetShiftOrdersQuery({ID_Shift: id as string, FStatuses: cases[selected.value]},
        {refetchOnMountOrArgChange: true});

    return (
        <Container>
            <Box flex={true} flexDirection={'column'}>
                <Col span={3}>
                    <Paragraph as={'p'} weight={'400'} colorType={'main'} fontSize={16}>Заказы</Paragraph>
                </Col>
                <Row>
                    <Col span={4}>
                        <Paragraph as={'span'} weight={'400'} fontSize={14} colorType={'main'}>Дата:</Paragraph>
                    </Col>
                    <Col span={8} direction={'flex-end'}>
                        {getPeriod(state?.data as string)}
                    </Col>
                </Row>
                <Separator space={5}/>
                <Row>
                    <Col span={4}>
                        <Paragraph as={'span'} weight={'400'} fontSize={14} colorType={'main'}>Объект:</Paragraph>
                    </Col>
                    <Col span={8} direction={'flex-end'}>
                        {state?.ObjName}
                    </Col>
                </Row>
                <Separator space={5}/>
                <Row>
                    <Col span={2}>
                        <Paragraph as={'span'} fontSize={14} weight={'400'} colorType={'main'}>Статус</Paragraph>
                    </Col>
                    <Col span={10}>
                        <Switcher
                            height={43}
                            options={statusProductOptions}
                            selected={selected}
                            onSwitch={setSelected}
                        />
                    </Col>
                </Row>
                <Separator space={10}/>
                {isLoading
                    ? <Loader type={'large'}/>
                    : data?.map(item => <Card key={item.OrderID} item={item}/>)}
            </Box>
        </Container>
    );
};

