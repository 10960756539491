import React from 'react';
import styled from 'styled-components';
import {Icon} from '../../assets/icon';
import {themes} from '../../utils';
import {useLocation, useNavigate} from 'react-router-dom';


export const Footer = () => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    return (
        <FooterContainer>
            <NavButton width={20} active={pathname === '/'} onClick={() => navigate('/')}>
                <Icon type={'home'} color={themes.colors.textColor}/>
            </NavButton>
            <NavButton width={20} active={pathname === '/map'} onClick={() => navigate('/map')}>
                <Icon type={'map'} color={themes.colors.textColor}/>
            </NavButton>
            <NavButton width={20} active={pathname === '/account'} onClick={() => navigate('/account')}>
                <Icon type={'user_nav'} color={themes.colors.textColor}/>
            </NavButton>
            <NavButton width={20} active={pathname === '/vacancies'} onClick={() => navigate('/vacancies')}>
                <Icon type={'vacancies'} color={themes.colors.textColor}/>
            </NavButton>
            <NavButton width={20} active={true} onClick={() => navigate(-1)}>
                <BackBtnWrap>
                    <Icon type={'back'} color={themes.colors.textColor}/>
                </BackBtnWrap>
            </NavButton>
        </FooterContainer>
    );
};

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  width: 95%;
  height: 60px;
  margin: 0 auto 30px;
  background-color: var(--white-color);
  border-radius: 12px;
  border: 2px solid var(--border-color);
  padding: 2px;
  position: relative;
  z-index: 2;
`;


const NavButton = styled.button.withConfig({
        shouldForwardProp: (prop) => !['width', 'active'].includes(prop)
    }
)<{ width: number, active: boolean }>`
  width: ${({width}) => width - 1}%;
  height: 52px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${({active}) => active ? '#F0F2F8' : '#FFFFFF'};

  &:first-of-type {
    border: 1px solid var(--border-color);
  }

  &:last-of-type {
    border: 1px solid var(--border-color);
  }
`;

const BackBtnWrap = styled.div`
  width: 45px;
  height: 45px;
  background-color: #F0F0F0;
  border: 3px solid #ffffff;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
