import React, {useState} from 'react';
import {Placemark} from '@pbe/react-yandex-maps';
import {useNavigate} from 'react-router-dom';
import {Vacancy} from '../store/types';
import {OrderBalloon} from './OrderBalloon';

const deliveryIcon = require('../../../assets/images/icons/delivery-icon.png');

interface RenderOrderPointProps {
    item: Vacancy;
    ymaps: any;
}


export const RenderOrderPoint = ({item}: RenderOrderPointProps) => {
    const navigate = useNavigate();

    const [show, setShow] = useState<boolean>(false);

    return (
        <>
            <Placemark
                geometry={[item.lat, item.lon]}
                options={{
                    iconLayout: 'default#image',
                    iconImageHref: deliveryIcon,
                    iconOffset: [0, 0],
                    iconImageSize: [30, 30],

                }}
                onClick={() => setShow(true)}
                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
            />
            {show ? (
                <OrderBalloon
                    item={item}
                    onMore={() => navigate('/order-details')}
                    onClose={() => setShow(false)}
                />
            ) : null}
        </>
    );
};
