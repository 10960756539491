import React from 'react';
import {IDetectedBarcode, Scanner} from '@yudiel/react-qr-scanner';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {BackButton, ScannerContainer} from './styles';
import {Icon} from '../../assets/icon';
import {asyncUpdateBarcode} from './store/scanner.slice';
import {useAppDispatch} from '../../hooks/hook-store';

export const scannerFormats: BarcodeFormat[] = [
    'qr_code',
    'itf',
    'ean_8',
    'ean_13',
    'codabar',
    'code_93',
    'code_39',
    'code_128',
    'upc_a',
    'upc_e',
];

function useSize() {
    let width = window.innerWidth;
    let height = window.innerHeight;
    if (width < height) {
        return {
            width,
            height: width
        };
    } else {
        return {
            width: height,
            height
        };
    }
}

export const ScannerPage = () => {
    const dispatch = useAppDispatch();
    const location: Location = useLocation();
    const {state} = location as Location & { state: { pathFrom: string, ID_Shift: string } };
    const navigate = useNavigate();

    const handleOnScan = (barcode: IDetectedBarcode[]) => {
        const rawValue = barcode[0].rawValue;
        if (rawValue.length > 0) {
            dispatch(asyncUpdateBarcode({barcode: rawValue, ID_Shift: state?.ID_Shift}))
                .then(() => {
                    navigate(`${state.pathFrom}`);
                });
        }
    };
    return (
        <>
            <ScannerContainer
                {...useSize()}
            >
                <Scanner
                    formats={scannerFormats}
                    onScan={(barcode) => handleOnScan(barcode)}
                />
            </ScannerContainer>
            <BackButton onClick={() => navigate(-1)}>
                <Icon type={'back'} width={20} height={20} color={'#000000'}/>
            </BackButton>
        </>
    );
};
