import React, {useEffect, useMemo, useRef, useState} from 'react';
import {themes} from '../../utils';
import {useNavigate} from 'react-router-dom';
import {
    Back,
    BackBtnWrap,
    ButtonDay,
    Controls,
    Header,
    ListDays,
    MapContainer,
    NavigatePanel,
    TopPanel,
    VacancyBtn
} from '../map/styled';
import {Map} from '@pbe/react-yandex-maps';
import {Button, Paragraph} from '../../fields';
import {Icon} from '../../assets/icon';
import {useGetVacanciesQuery} from './store/vacancies.api';
import {RenderOrderPoint} from './components';
import {Vacancy} from './store/types';
import dayjs from 'dayjs';

const getDatesForNextWeek = (): string[] => {
    const days = [];
    for (let i = 0; i < 7; i++) {
        const date = dayjs().add(i, 'day');
        days.push(date.format('D MMM dd'));
    }
    return days;
};

export const Vacancies = () => {
    const mapRef = useRef<any>(null);
    const navigate = useNavigate();
    const [zoom, setZoom] = useState(10);
    const [ymaps, setYmaps] = useState<any>();

    const days = getDatesForNextWeek();

    const {data, isLoading} = useGetVacanciesQuery();

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setZoom(zoom);
        }

    }, [zoom]);

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom < 19 ? prevZoom + 1 : 19); // Максимальный зум - 19
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => prevZoom > 0 ? prevZoom - 1 : 0); // Минимальный зум - 0
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    mapRef.current.setCenter([latitude, longitude]);
                },
                (error) => {
                    console.error('Error getting user\'s location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const renderOrder = useMemo(() => {

        return <>{ymaps && data?.map((item: Vacancy) =>
            <RenderOrderPoint
                key={item.id}
                item={item} ymaps={ymaps}/>)}</>;
    }, [data, ymaps]);

    return (
        <MapContainer>
            <Map
                defaultState={{zoom: 10, center: [55.755864, 37.617698]}}
                width={window.innerWidth}
                height={window.innerHeight}
                instanceRef={mapRef}
                onLoad={ymaps => {
                    setYmaps(ymaps);
                }}
            >
                <TopPanel>
                    <Header>
                        <Paragraph as={'span'} colorType={'main'} fontSize={16} weight={'400'}>
                            Карта вакансии
                        </Paragraph>
                    </Header>
                    <ListDays>
                        {days.map((item, idx) =>
                            <ButtonDay key={idx}>{item}</ButtonDay>)}
                    </ListDays>
                </TopPanel>
                <Controls>
                    <Button full={false} onClick={handleZoomIn}>
                        <Icon type={'zoomIn'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>

                    <Button full={false} onClick={handleZoomOut}>
                        <Icon type={'zoomOut'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>
                    <Button full={false} onClick={getUserLocation}>
                        <Icon type={'location'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>
                </Controls>
                <NavigatePanel>
                    <VacancyBtn full={true}>Курьер</VacancyBtn>
                    <VacancyBtn full={true}>Пицца-мейкер</VacancyBtn>
                    <Back full={false} onClick={() => navigate('/')}>
                        <Icon type={'home'} width={20} height={20} color={themes.colors.borderColor}/>
                    </Back>
                    <Back full={false} onClick={() => navigate(-1)}>
                        <BackBtnWrap>
                            <Icon type={'back'} width={20} height={20} color={themes.colors.borderColor}/>
                        </BackBtnWrap>
                    </Back>
                </NavigatePanel>

                {renderOrder}
            </Map>
        </MapContainer>
    );
};
