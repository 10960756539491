import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../hooks/hook-store';
import {Map} from '@pbe/react-yandex-maps';
import {Back, BackBtnWrap, Controls, Header, MapContainer, NavigatePanel, TopPanel} from './styled';
import {Button, Paragraph, Switcher} from '../../fields';
import {Icon} from '../../assets/icon';
import {themes} from '../../utils';
import {Option} from '../../store/types';
import {statusProductOptions} from '../shiftOrders/ShiftOrders';

export const MapCustom = () => {
    const mapRef = useRef<any>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [zoom, setZoom] = useState(10);
    const [ymaps, setYmaps] = useState<any>();
    const [selected, setSelected] = useState<Option>(statusProductOptions[0]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setZoom(zoom);
        }

    }, [zoom]);

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    mapRef.current.setCenter([latitude, longitude]);
                },
                (error) => {
                    console.error('Error getting user\'s location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom < 19 ? prevZoom + 1 : 19); // Максимальный зум - 19
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => prevZoom > 0 ? prevZoom - 1 : 0); // Минимальный зум - 0
    };

    return (
        <MapContainer>
            <Map
                defaultState={{zoom: 10, center: [55.755864, 37.617698]}}
                width={window.innerWidth}
                height={window.innerHeight}
                instanceRef={mapRef}
                onLoad={ymaps => {
                    setYmaps(ymaps);
                }}
            >
                <TopPanel>
                    <Header>
                        <Paragraph as={'span'} colorType={'main'} fontSize={16} weight={'400'}>
                            Карта моих доставок
                        </Paragraph>
                    </Header>
                    <Switcher
                        height={43}
                        options={statusProductOptions}
                        selected={selected}
                        onSwitch={setSelected}
                    />
                </TopPanel>
                <Controls>
                    <Button full={false} onClick={handleZoomIn}>
                        <Icon type={'zoomIn'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>

                    <Button full={false} onClick={handleZoomOut}>
                        <Icon type={'zoomOut'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>
                    <Button full={false} onClick={getUserLocation}>
                        <Icon type={'location'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>

                </Controls>
                <NavigatePanel>
                    <Back full={false} onClick={() => navigate('/')}>
                        <Icon type={'home'} width={20} height={20} color={themes.colors.borderColor}/>
                    </Back>
                    <Back full={false} onClick={() => navigate(-1)}>
                        <BackBtnWrap>
                            <Icon type={'back'} width={20} height={20} color={themes.colors.borderColor}/>
                        </BackBtnWrap>
                    </Back>
                </NavigatePanel>
            </Map>
        </MapContainer>
    );
};
