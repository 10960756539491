import React from 'react';
import {Balloon, BalloonOverlay, BalloonWrapper, CloseBtn, Footer, Link} from './styles';
import {Col, Row, themes} from '../../../utils';
import {Paragraph} from '../../../fields';
import {Icon} from '../../../assets/icon';
import {Vacancy} from '../store/types';

interface OrderBalloonProps {
    item: Vacancy;
    onClose: () => void;
    onMore: () => void;
}


export const OrderBalloon = ({item, onClose, onMore}: OrderBalloonProps) => {
    return (
        <BalloonWrapper>
            <Balloon>
                <Row style={{borderBottom: `1px solid ${themes.colors.placeholder}`}}>
                    <Col span={10}>
                        <Paragraph as={'span'} colorType={'main'} weight={'400'}
                                   fontSize={18}>Вакансия</Paragraph>
                    </Col>
                    <Col span={2}>
                        <CloseBtn onClick={onClose}>
                            <Icon type={'close'} width={20} height={20} color={themes.colors.main}/>
                        </CloseBtn>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Paragraph as={'span'} colorType={'main'} weight={'400'}
                                   fontSize={14}>{item.PlaceType}</Paragraph></Col>
                    <Col span={8} direction={'flex-end'}>
                        <Paragraph as={'span'} colorType={'main'} weight={'400'}
                                   fontSize={16}>{item.ObjName}</Paragraph>
                    </Col>
                </Row>
                <Footer>
                    <Row>
                        <Col span={4}>
                            <Paragraph as={'span'} colorType={'main'} weight={'400'}
                                       fontSize={14}>Упр.</Paragraph></Col>
                        <Col span={8} direction={'flex-end'}>
                            <Paragraph as={'span'} colorType={'main'} weight={'400'}
                                       fontSize={16}>{item.upr}</Paragraph>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={4}>
                            <Paragraph as={'span'} colorType={'main'} weight={'400'}
                                       fontSize={14}>Тел.</Paragraph></Col>
                        <Col span={8} direction={'flex-end'}>
                            <Link href={`tel:${item.phone}`}>{item.phone}</Link>
                        </Col>
                    </Row>
                </Footer>
            </Balloon>
            <BalloonOverlay onClick={onClose}/>
        </BalloonWrapper>
    );
};

