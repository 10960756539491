import {api} from '../../../store/api';
import {Vacancy} from './types';

const vacanciesApi = api.injectEndpoints({
    endpoints: build => ({
        getVacancies: build.query<Vacancy[], void>({
            query: () => ({
                url: 'vacancies/map',
                method: 'GET'
            }),
            providesTags: ['vacancies'],
            transformResponse: ({listObjects}: { listObjects: Vacancy[] }) => {
                return listObjects.filter(i => i.IsActive);
            }
        })
    }), overrideExisting: true
});

export const {
    useGetVacanciesQuery
} = vacanciesApi;
